export function completeaddress(addressEl, addressIdEl, outputEl, validator, id, addressOutput) {
    $('#' + outputEl)
        .find('button')
        .on('click', removeAddressFromClick);

    $('#' + addressEl).autocomplete({
        source: function (request, response) {
            $.ajax({
                url: '/api/Autocomplete/Street',
                dataType: 'json',
                data: { term: request.term },
                contentType: 'application/json; charset=utf-8',
                success: function (data) {
                    response(
                        $.map(data, function (item) {
                            return {
                                label: item.Text,
                                value: item.Text,
                                id: item.Id,
                                streetname: item.Streetname,
                                housenumber: item.Housenumber,
                                floor: item.Floor,
                                door: item.Door,
                                postcode: item.Postcode,
                                city: item.City,
                            };
                        }),
                    );
                },
            });
        },
        minLength: 3,
        select: handleCallback,
        change: handleChangeCallback,
    });

    function getAddressById(addressId) {
        $.ajax({
            url: '/api/Autocomplete/AddressById',
            dataType: 'json',
            data: { addressId: addressId },
            contentType: 'application/json; charset=utf-8',
            success: function (data) {
                addAddress(
                    $.map(data, function (item) {
                        return {
                            label: item.Text,
                            value: item.Text,
                            id: item.Id,
                            streetname: item.Streetname,
                            housenumber: item.Housenumber,
                            floor: item.Floor,
                            door: item.Door,
                            postcode: item.Postcode,
                            city: item.City,
                        };
                    })[0],
                );
            },
        });
    }

    if (id && addressOutput) {
        setOutputAddress(id, addressOutput);
    } else if ($('#' + addressIdEl)[0]?.value && $('#' + addressIdEl)[0]?.value !== '') {
        getAddressById($('#' + addressIdEl)[0].value);
    }

    function handleChangeCallback(event, ui) {
        var lookupAddress = false;

        if (!ui.item) {
            lookupAddress = true;
        }

        if (lookupAddress) {
            $('#' + addressEl).autocomplete('search');
        }
    }

    function handleCallback(event, ui) {
        if (ui.item === null) {
            removeAddress();
        } else {
            addAddress(ui.item);
        }
    }

    function addAddress(addressObject) {
        let prettyAddress = addressObject.streetname + ' ' + addressObject.housenumber;
        if (addressObject.floor) {
            prettyAddress += ', ' + addressObject.floor;
        }
        if (addressObject.door) {
            prettyAddress += ' ' + addressObject.door;
        }
        prettyAddress += '\n';
        prettyAddress += addressObject.postcode + ' ' + addressObject.city;

        setOutputAddress(addressObject.id, prettyAddress);
    }

    function removeAddressFromClick() {
        $('#' + addressEl).val('');
        setOutputAddress('', '');
    }

    function removeAddress() {
        setOutputAddress('', '');
    }

    function setOutputAddress(id, addressOutput) {
        addressOutput = addressOutput.replace('<br>', '\n');
        $('#' + addressIdEl).val(id);
        $('#' + outputEl)
            .find('.address-output')
            .text(addressOutput);
        if (id !== '') {
            $('#' + outputEl).show();
            $('#' + addressEl).hide();
        } else {
            $('#' + outputEl).hide();
            $('#' + addressEl).show();
            $('#' + addressEl).focus();
        }
        if (validator === 'jquery') {
            $('#' + addressIdEl).valid();
        } else {
            Foundation.libs.abide.validate($('#' + addressIdEl).get(), { type: 'input' }, false);
        }
    }
}

export function completecity(postcode, city) {
    $('#' + postcode).autocomplete({
        source: function (request, response) {
            $.ajax({
                url: '/api/Autocomplete/City',
                dataType: 'json',
                data: { term: request.term },
                contentType: 'application/json; charset=utf-8',
                success: function (data) {
                    $.map(data, function (item) {
                        return {
                            city: item.city,
                        };
                    });

                    response(
                        $.map(data, function (value) {
                            return {
                                label: value.Postcode + ' ' + value.City,
                                value: value.Postcode,
                                city: value.City,
                            };
                        }),
                    );
                },
            });
        },
        select: function (event, ui) {
            $('#' + city).val(ui.item.city);
            $('#' + city)
                .parents('.checkout-form-group')
                .addClass('is-filled');
            Foundation.libs.abide.validate($('#' + city).get(), { type: 'input' }, false);
        },
        change: function (event, ui) {
            const post = $('#' + postcode).val();

            if (ui.item === null) {
                $('#' + city).val('');
                $('#' + city)
                    .parents('.checkout-form-group')
                    .removeClass('is-filled');
            } else {
                $('#' + city).val(ui.item.city);
            }

            if (post.length === 4) {
                $.ajax({
                    url: '/api/Autocomplete/City',
                    dataType: 'json',
                    data: { term: post },
                    contentType: 'application/json; charset=utf-8',
                    success: function (data) {
                        if (data.length === 1) {
                            $('#' + city).val(data[0].City);
                            Foundation.libs.abide.validate($('#' + city).get(), { type: 'input' }, false);
                        }
                    },
                });
            }
        },
    });
}

export function completsugestionsBody(term, suggestionClickCallback) {
    {
        $('.' + term)
            .autocomplete({
                source: function (request, response) {
                    $.ajax({
                        url: '/api/Search/Suggestions',
                        dataType: 'json',
                        data: { term: request.term },
                        contentType: 'application/json; charset=utf-8',
                        success: function (data) {
                            response(
                                $.map(data, function (item) {
                                    return {
                                        label: item,
                                    };
                                }),
                            );
                        },
                    });
                },
                minLength: 3,
                select: function (event, ui) {
                    $('.' + term).val(ui.item.value);
                    $('#searchform').submit();
                },
                appendTo: '.site-search-container__autocomplete-body',
            })
            .keyup(function (e) {
                if (e.which === 13) {
                    $('.ui-menu-item').hide();
                }
            });

        $.ui.autocomplete.prototype._renderItem = function renderAutoComplete(ul, item) {
            return $('<li></li>')
                .data('item.autocomplete', item)
                .append('<span>' + item.label + '</span>')
                .on('click', () => suggestionClickCallback?.(item))
                .appendTo(ul);
        };
    }
}
