import { TweenLite } from '../../../../node_modules/gsap';

export function addtobasket(productcode, quantity, successCallback, errorCallback) {
    $.ajax({
        type: 'POST',
        url: '/api/Basket/Add',
        content: 'application/json; charset=utf-8',
        dataType: 'json',
        data: {
            code: productcode,
            quantity,
        },
        success: successCallback,
        error: errorCallback,
    });
}

export function addtoPo(productcode, quantity, successCallback, errorCallback) {
    $.ajax({
        type: 'POST',
        url: '/api/Basket/AddAndReplacePurchaseOrder',
        content: 'application/json; charset=utf-8',
        dataType: 'json',
        data: {
            code: productcode,
            quantity,
        },
        success: successCallback,
        error: errorCallback,
    });
}

export function removefrombasket(lineitemid, successCallback, errorCallback) {
    $.ajax({
        type: 'POST',
        url: '/api/Basket/Remove',
        content: 'application/json; charset=utf-8',
        dataType: 'json',
        data: {
            lineitemid,
        },
        async: false,
        success: successCallback,
        error: errorCallback,
    });
}

export function updatebasketpreview(resultCount, getHeaderBasketUrl) {
    const countUpdate = $('#basket-preview-countupdate');
    const countUpdateMobile = $('#basket-preview-mobile-countupdate');
    const basketOrderButton = $('#basketPreviewOrderButton');
    const basketIcon = $('.js-basket-icon');

    countUpdate.html(resultCount);
    countUpdateMobile.html(resultCount);
    if (resultCount > 0) {
        countUpdate.removeAttr('style');
        countUpdateMobile.removeAttr('style');
        basketOrderButton.removeClass('disabled');
    } else {
        basketIcon.removeClass('active');
        countUpdate.attr('style', 'display: none;');
        countUpdateMobile.attr('style', 'display: none;');
        basketOrderButton.addClass('disabled');
    }

    $.ajax({
        type: 'GET',
        url: getHeaderBasketUrl,
        content: 'application/html; charset=utf-8',
        dataType: 'html',
        success(data) {
            const $basketIcon = $('.js-basket-icon');
            $('#basket-preview-ajaxupdate').html(data);
            if ($basketIcon.hasClass('active') && resultCount > 0) {
                // Reopen basket when it was open before the update
                const $basketPreview = $('#js-basket-preview');
                TweenLite.set($basketPreview, { height: 'auto' });
            }

            if ($(document).find('.shopping-cart-page').length > 0) {
                window.location.reload();
            }
        },
    });
}
