export function product_entrance($myBlockIdentifier, mySize, myHasProductPageHandler, myProductPageHandlerUrl) {
    if (myHasProductPageHandler) {
        $myBlockIdentifier.find('.blockarea').click(function () {
            window.location.href = myProductPageHandlerUrl;
            return false;
        });
    }
    $myBlockIdentifier.find('.product-entranceblock-' + mySize + '__legallink').on('click', function onClick(e) {
        e.stopPropagation();
        $(this)
            .closest('.product-entranceblock-' + mySize)
            .find('.product-entranceblock-conditions')
            .fadeIn('fast')
            .on('click', function (event) {
                event.stopPropagation();
            });
    });
    $myBlockIdentifier.find('.modal-content__close-button').on('click', function onClick() {
        $(this)
            .closest('.product-entranceblock-' + mySize)
            .find('.product-entranceblock-conditions')
            .fadeOut('fast');
    });
    // Scale price font size as much as possible
    var $priceElem = $($myBlockIdentifier).find('.rhomb-small__content__price');
    $priceElem.textfill({
        widthOnly: true,
        changeLineHeight: false,
        debug: false,
        success: function () {
            $priceElem.addClass('resized');
        },
    });
}
