export function payment_selection_page() {
    $(() => {
        const PaymentSelection = {};
        const checkoutPaymentButton = document.querySelector('.checkout-payment-button');

        PaymentSelection.select = ($elem) => {
            checkoutPaymentButton.removeAttribute('disabled');

            var activePanel = $('.js-payment.checkout-payment-is-selected');
            var curPanel = $elem.closest('.js-payment');
            if (curPanel.hasClass('checkout-payment-is-selected')) {
                return;
            }

            activePanel.removeClass('checkout-payment-is-selected');
            activePanel.find('.checkout-payment-content').slideUp(400);

            curPanel.find('.checkout-payment-content').slideDown(400, function () {
                curPanel.addClass('checkout-payment-is-selected');
            });
        };

        $('.js-payment .checkout-payment-header').on('click', function onClick() {
            const $check = $(this).find('input[type=radio]');
            const $paymentForm = $(this).next('.checkout-payment-content').find('form');
            const paymentFormId = $paymentForm.attr('id');

            $check.prop('checked', true);

            checkoutPaymentButton.setAttribute('form', paymentFormId);

            PaymentSelection.select($check);
        });
    });
}
