// Store the anchor value on header.js load, before any other javascript is executed (because the url may be changed)
import { TweenLite } from 'gsap';

import { completsugestionsBody } from './autocomplet';
const anchorValue = window.location.hash;

export function initializeHeader() {
    applyHoverEvent();
    applyBurgerClick();
    applyUserClick();
    applyHeaderAnime();
    applyMobileMenuEvent();
    initDisplayPreviewBasket();
    initMobileNavSearch();

    // Handle touch states
    //! !problems with Moderniz
    // if (Modernizr.touch)
    if ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0)
        applyTouchStates.init();
}

function hidePreviewBasket() {
    const $basketIcon = $('.js-basket-icon');

    if ($basketIcon.hasClass('active')) {
        const $basketPreview = $('#js-basket-preview');

        TweenLite.to($basketPreview, 0.2, { height: 0 });
        $basketIcon.removeClass('active');
    }
}

function hideMobileMenu() {
    if ($('.burger-icon').hasClass('is-active')) {
        $('.burger-icon').removeClass('is-active');
        $('.main-nav--mobile--menu > ul').fadeOut('fast');
        $('.container header:first').removeClass('touch-scroll');
    }
}

const applyMobileMenuEvent = () => {
    $('.main-nav--mobile--menu--dropdown .menu-click-event').on('click', function onClick(e) {
        e.preventDefault();

        const linkedMenuId = $(this).data('linkedmenuid');

        $(this).parents('ul').first().hide();
        $('#' + linkedMenuId).fadeIn('fast');
    });
};

const applyBurgerClick = () => {
    $('.burger-icon').on('click', function onClick(e) {
        e.preventDefault();
        hidePreviewBasket();
        toggleUser(false);

        if ($(this).hasClass('is-active')) {
            toggleBurger(false);
        } else {
            toggleBurger(true);
        }
    });
};

const toggleBurger = (visible) => {
    if (visible) {
        openSubMenu('.burger-icon', '.main-nav--mobile--burger-menu-container');
    } else {
        closeSubMenu('.burger-icon', '.main-nav--mobile--burger-menu-container');
    }
};

const applyUserClick = () => {
    $('.user-icon').on('click', function onClick(e) {
        e.preventDefault();
        hidePreviewBasket();
        toggleBurger(false);

        if ($(this).hasClass('is-active')) {
            toggleUser(false);
        } else {
            toggleUser(true);
        }
    });
};

const toggleUser = (visible) => {
    if (visible) {
        openSubMenu('.user-icon', '#main-nav--mobile--user--menu .main-nav--mobile--menu--hidden');
    } else {
        closeSubMenu('.user-icon', '#main-nav--mobile--user--menu .main-nav--mobile--menu--hidden');
    }
};

const openSubMenu = (button, menu) => {
    $('body').addClass('offcanvas');
    $(button).addClass('is-active');
    $(menu).addClass('main-nav--mobile--menu--visible');
    $('header:first').addClass('touch-scroll');
};

const closeSubMenu = (button, menu) => {
    $('body').removeClass('offcanvas');
    $(button).removeClass('is-active');
    $(menu).removeClass('main-nav--mobile--menu--visible');
    $('.container header:first').removeClass('touch-scroll');
};

const applyTouchStates = {
    init() {
        // FIXME: Firefox returns true for modernizr.touch on desktups - needs to be fixed!
        $('.main-nav__menu .main-nav__link').on('click', applyTouchStates.open);
        $('.mega-dropdown .touch-close').on('click', applyTouchStates.close);
    },

    open(event) {
        if (!$(this).hasClass('is-touched')) {
            $('.main-nav__link.is-touched').removeClass('is-touched');
            $(this).addClass('is-touched');
            // $(window).on("click", applyTouchStates.close);
            event.preventDefault();
        }
    },

    close(event) {
        $('.main-nav__link.is-touched').removeClass('is-touched');
        $('.main-nav__menu .is-open').removeClass('is-open');
        event.preventDefault();
    },

    // touch outside nav should close mega
    // close: function (event) {
    //    if ($(event.target).closest(".main-nav__menu").length === 0) {
    //        $('.main-nav__menu .is-open').removeClass('is-open');
    //        $(document).off("click", applyTouchStates.close);
    //        event.preventDefault();
    //    }
    // }
};

const applyHoverEvent = function onHover() {
    $('.main-nav__menu > ul').hoverIntent({
        over() {
            // close open mega
            $('.main-nav--desktop .is-open').removeClass('is-open');

            // open new mega
            const className = $(this).children('a:first').attr('data-target');
            const $target = $('.' + className);

            $target.addClass('is-open').prev('a').addClass('is-open');
        },
        out() {
            // do nothing
        },
        selector: '> li',
        interval: 20,
        sensitivity: 2,
    });

    $('.main-nav--desktop .main-nav__menu').on('mouseleave', () => {
        // close open mega
        $('.main-nav--desktop .is-open').removeClass('is-open');
    });
};

const applyHeaderAnime = function onAnime() {
    if (!$('.shouldanimateheader').length) {
        return;
    }
    const mainMenu = $('.main-nav--desktop');
    const mainMenuLogo = $('.site-logo img', mainMenu);
    const mainMenuList = $('.main-nav__menu .main-nav__link');
    const mainMenuIcons = $('.main-nav__right img');
    const mainMenuIconsContainer = $('.main-nav__right > a');
    const header = $('header');

    // Hide Header on on scroll down
    let didScroll;

    $(window).scroll(() => {
        didScroll = true;
    });

    $(document).ready(() => {
        if (anchorValue && anchorValue !== '#') {
            // If the url we are loading contains any anchor ref, hide the header immediately
            TweenLite.set(header, { top: '-40px' });
            TweenLite.set(mainMenu, { height: '70px' });
            TweenLite.set(mainMenuLogo, { margin: '12px' });
            TweenLite.set(mainMenuList, { padding: '20px 15px' });
            TweenLite.set(mainMenuIcons, { margin: '20px' });
            TweenLite.set(mainMenuIconsContainer, { width: '70px', height: '70px' });
            header.addClass('scrolled');
            $('.container').addClass('scrolled');
        }
    });

    setInterval(() => {
        didScroll &&= false;
    }, 100);
};

const lockBodyScroll = () => {
    if (window.innerWidth < 640) {
        document.body.style.overflow = 'hidden';
    }
};

const unlockBodyScroll = () => (document.body.style.overflow = 'auto');

/**
 * Display content in basket when user clicks on basket icon
 */
const initDisplayPreviewBasket = function onBasket() {
    const $basketIcon = $('.js-basket-icon');

    if (!$basketIcon.hasClass('js-basket-no-click')) {
        $basketIcon.on('click', (e) => {
            const $basketPreview = $('#js-basket-preview');

            hideMobileMenu();

            if (!$basketIcon.hasClass('active')) {
                TweenLite.set($basketPreview, { height: 'auto' });
                TweenLite.from($basketPreview, 0.2, { height: 0 });
                $basketIcon.addClass('active');
                lockBodyScroll();
            } else {
                unlockBodyScroll();
                hidePreviewBasket();
            }

            e.preventDefault();
        });
    }

    $(document).on('click', '#basketPreviewGotoBasketButton', function onClick(e) {
        if ($(this).hasClass('disabled')) e.preventDefault();
    });
};

const initMobileNavSearch = () => {
    const searchInputEl = document.querySelector('.mobile-menu-search__input');
    const searchClearButtonEl = getClearSearchButton();
    const searchButtonEl = document.querySelector('.mobile-menu-search__search-button');

    if (!searchInputEl) return;

    completsugestionsBody('mobile-menu-search__input', (item) => {
        window.location.href = `/search?query=${item.value}`;
    });

    searchButtonEl.addEventListener('click', () => {
        window.location.href = `/search?query=${searchInputEl.value}`;
    });

    searchInputEl.addEventListener('input', () => {
        if (searchInputEl.value) {
            showClearSearchButton();
        } else {
            hideClearSearchButton();
        }
    });

    searchClearButtonEl.addEventListener('click', () => {
        searchInputEl.value = '';
        hideClearSearchButton();
    });
};

const getClearSearchButton = () => document.querySelector('.mobile-menu-search__clear-button');

const showClearSearchButton = () => {
    const searchClearButtonEl = getClearSearchButton();

    searchClearButtonEl.classList.add('is-visible');
};

const hideClearSearchButton = () => {
    const searchClearButtonEl = getClearSearchButton();

    searchClearButtonEl.classList.remove('is-visible');
};

$('#SelfServiceDropdownButton').on('click', hidePreviewBasket);
