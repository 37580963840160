//inserts ?wmode=transparent at end of url on youtube video to stop overlapping on menu

export function content_entrance($myBlockIdentifier) {
    // Init tabs
    $myBlockIdentifier.on('click', '.tab-title a', function (e) {
        e.preventDefault();

        const tabId = $(this).attr('aria-controls');
        const tabToActivate = $myBlockIdentifier.find(tabId);

        $(this).parent().addClass('active').siblings().removeClass('active');
        $myBlockIdentifier.find('section[role="tabpanel"]').removeClass('active');
        tabToActivate.addClass('active');
    });

    // Init tab slider
    $myBlockIdentifier.on('click', '.tab-slider a', function (e) {
        e.preventDefault();
        const slideEl = $(e.target).closest('.slick-slide');
        const slideIndex = parseInt(slideEl.attr('data-slick-index'));
        const tabId = $(this).attr('aria-controls');
        const tabToActivate = $myBlockIdentifier.find(tabId);

        console.log(slideIndex);

        // Slide to tab
        $myBlockIdentifier.find('.tab-slider').slick('slickGoTo', slideIndex);

        // Activate tab
        $myBlockIdentifier.find('.tab-slider a').removeClass('active');
        $myBlockIdentifier.find(`[aria-controls="${tabId}"]`).addClass('active');

        // Activate tab content
        $myBlockIdentifier.find('section[role="tabpanel"]').removeClass('active');
        tabToActivate.addClass('active');
    });

    $myBlockIdentifier.find('.tab-slider').slick({
        infinite: true,
        dots: false,
        arrows: false,
        autoplay: false,
        centerMode: true,
        variableWidth: true,
        slidesToShow: 1,
    });
}
