import { completeaddress } from '../common/autocomplet';

export function address(GUID, validator, addressId, prettyAddress) {
    $(function () {
        completeaddress(
            getIdByName('SearchAd', GUID),
            getIdByName('AddressId', GUID),
            GUID + '_output',
            validator,
            addressId,
            prettyAddress,
        );
    });
}

// Gets the DOM element's id by its name.
function getIdByName(name, GUID) {
    return document.querySelector('[name="' + GUID + '_' + name + '"]').getAttribute('id');
}
