export function faq_list(container) {
    $('dt button', container).click(function onClick() {
        const controls = '#' + $(this).attr('aria-controls');
        if ($(this).attr('aria-expanded') === 'true') {
            closeFaq($(this), $(controls));
        } else {
            openFaq($(this), $(controls));
        }
        closeFaqs($('dd .desc', '.faq-list').not($(controls)));
    });
}

function openFaq(button, el) {
    button.attr('aria-expanded', true);
    el.slideDown();
}

function closeFaq(button, el) {
    button.attr('aria-expanded', false);
    el.slideUp();
}

function closeFaqs(els) {
    els.slideUp(closeFaqsCallback);
}

function closeFaqsCallback() {
    const id = $(this).attr('id');
    const controledBy = $('[aria-controls=' + id + ']');
    controledBy.attr('aria-expanded', false);
}
