//define('video-slider', ['slickslider'], function () {

export function video_slider($myBlockIdentifier) {
    $(() => {
        let currentArticle = $myBlockIdentifier.find('.video-article > a').prop('href');

        $myBlockIdentifier.find('.video-module-slider').on('init', function () {
            $myBlockIdentifier.find('.video-module-slider').find('.slick-current > div').addClass('current-video');
        });

        $myBlockIdentifier.find('.video-module-slider').slick({
            dots: false,
            arrows: true,
            autoplay: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            prevArrow: '<a class="slide-prev"><i class="fa fa-angle-left"></i></a>',
            nextArrow: '<a class="slide-next"><i class="fa fa-angle-right"></i></a>',
            responsive: [
                {
                    breakpoint: 640,
                    settings: {
                        dots: true,
                        slidesToShow: 2,
                    },
                },
            ],
        });

        $myBlockIdentifier.find('[data-video-id] figure').click(function onClick(e) {
            let $this = $(this);
            $this.parent().addClass('current-video');

            $myBlockIdentifier.find('.video-iframe').hide();
            let $parent = $(e.target).parent();

            let src = $(e.target).data('src');
            let videoId = $parent.data('video-id');
            let videoHeader = $parent.find('> span').text();
            let header = $parent.find('> p').text();
            let teaserText = $parent.find('> h5').text();
            let articleText = $parent.find('> div').html();
            let link = $parent.find('> a').text();
            let consentCategory = $myBlockIdentifier.find('.video-iframe iframe').data('category-consent');
            let videoUrl =
                'https://www.youtube.com/embed/' +
                videoId +
                '?rel=0&enablejsapi=1&modestbranding=0&showinfo=0&autohide=1&theme=light';

            $myBlockIdentifier.find('.video-to-display > div').attr('style', 'background-image:url(' + src + ')');
            $myBlockIdentifier.find('.video-to-display .video-module__text-overlay').html(header);

            if (CookieInformation.getConsentGivenFor(consentCategory)) {
                $myBlockIdentifier.find('.video-iframe iframe').attr('src', videoUrl);
            } else {
                $myBlockIdentifier.find('.video-iframe iframe').attr('data-consent-src', videoUrl);
            }

            $myBlockIdentifier.find('.video-article h3').html(videoHeader);
            $myBlockIdentifier.find('.video-article h5').html(teaserText);
            $myBlockIdentifier.find('.video-article .article-text').html(articleText);
            $myBlockIdentifier.find('.video-article a').attr('href', link);
            $myBlockIdentifier.find('.video-article').data('current-video', videoId);
            $myBlockIdentifier.find('.article-text').trigger('update');

            currentArticle = link;

            $myBlockIdentifier.find('.video-to-display').show();
        });

        $myBlockIdentifier.find('.social-buttons a').on('click', function onClick(e) {
            let socialType = $(e.currentTarget).attr('class');
            switch (socialType) {
                case 'social-icon-facebook':
                    window.open(
                        'https://www.facebook.com/sharer/sharer.php?u=' + currentArticle,
                        'FacebookShare',
                        'status=0,toolbar=0,menubar=1,resizable=1,width=480,height=240',
                    );
                    break;
                case 'social-icon-twitter':
                    window.open(
                        'https://twitter.com/intent/tweet?url=' +
                            currentArticle +
                            '&text=' +
                            encodeURIComponent(currentArticle),
                        'TwitterShare',
                        'status=0,toolbar=0,menubar=1,resizable=1,width=480,height=240',
                    );
                    break;
                case 'social-icon-linkedin':
                    window.open(
                        'https://www.linkedin.com/shareArticle?url=' +
                            currentArticle +
                            '&url=true&title=' +
                            encodeURIComponent(currentArticle) +
                            '&summary=' +
                            encodeURIComponent(currentArticle) +
                            '&source=falck.dk',
                        'LinkedIn',
                        'status=0,toolbar=0,menubar=1,resizable=1,width=520,height=570',
                    );
                    break;
                case 'social-icon-googleplus':
                    window.open(
                        'https://plus.google.com/share?url=' + currentArticle,
                        'GooglePlusShare',
                        'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600',
                    );
                    break;
                case 'social-icon-email':
                    $(this).attr(
                        'href',
                        'mailto:?subject=' +
                            encodeURIComponent(currentArticle) +
                            '&body=' +
                            encodeURIComponent(currentArticle),
                    );
                    return true;
                default:
                    break;
            }
            return false;
        });
    });
}
