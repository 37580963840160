var comparisonTables = [];

function ProductsTable(element) {
    this.element = element;
    this.table = this.element.children('.comparison-table');
    this.tableHeight = this.table.height();
    this.comparisonWrapper = this.table.find('.comparison-wrapper');
    this.productsWrapper = this.table.children('.comparison-wrapper');
    this.tableColumns = this.productsWrapper.children('.comparison-columns');
    this.products = this.tableColumns.children('.product');
    this.topBackground = this.table.find('.top-background');
    this.productsTopInfo = this.table.find('.top-info');
    this.featuresTopInfo = this.table.children('.features').children('.top-info');
    this.topInfoHeight = this.featuresTopInfo.innerHeight();
    this.updateProperties();

    this.showAnimatingHand = true;
    this.hasScrolledLeft = false;
    this.comparisonWrapper.on('scroll', this.updateSideScrolling.bind(this));
}

export function comparison_table() {
    $('.comparison-section').each(function setupTables() {
        //create a ProductsTable object for each .comparison-section
        comparisonTables.push(new ProductsTable($(this)));
    });

    setupDropdown(document.querySelector('.multi-select-dropdown'));
}

function setupDropdown(selectbox) {
    const button = $('.multi-select-dropdown-toggle', selectbox);
    const output = $('.multi-select-dropdown-output', selectbox);

    button.on('click', toggleMultiSelect);

    $("input[type='checkbox']", selectbox).on('change', function () {
        var checkedboxes = selectbox.querySelectorAll("input[type='checkbox']:checked");
        if (checkedboxes.length === 0) {
            output.text('');
        } else {
            output.text(checkedboxes.length);
        }
        if (checkedboxes.length >= 2) {
            showProductColumns(selectbox.querySelectorAll("input[type='checkbox']"));
        } else {
            showAllProductColumns();
        }
    });

    $('label', selectbox).on('keyup', function (e) {
        if (e.keyCode === 27) {
            closeMultiSelect(button[0]);
            button.focus();
        }
    });
}

function showProductColumns(checkedboxes) {
    var products = document.querySelectorAll('.comparison-wrapper .product');
    checkedboxes.forEach(function (box) {
        if (box.checked) {
            products[box.dataset.i].classList.remove('product__hide');
        } else {
            products[box.dataset.i].classList.add('product__hide');
        }
    });
}

function showAllProductColumns() {
    var products = document.querySelectorAll('.comparison-wrapper .product');
    products.forEach(function (box) {
        box.classList.remove('product__hide');
    });
}

ProductsTable.prototype.updateSideScrolling = function updateSideScrolling() {
    if (this.hasScrolledLeft === false && this.comparisonWrapper[0].scrollLeft > 0) {
        this.table[0].classList.add('has-scrolled-horisontal');
        this.hasScrolledLeft = true;
    }
};

ProductsTable.prototype.updateTopScrolling = function updateTopScrolling(scrollTop) {
    var offsetTop = this.table.offset().top - this.menuHeight;

    if (offsetTop <= scrollTop && offsetTop + this.tableHeight - this.topInfoHeight >= scrollTop) {
        // User is inside table
        if (!this.table.hasClass('top-fixed')) {
            this.table.addClass('top-fixed').removeClass('top-scrolling');
        }
        setTranformY(this.productsTopInfo, scrollTop - offsetTop);
        setTranformY(this.topBackground, scrollTop - offsetTop);
        //this.topBackground
    } else if (offsetTop <= scrollTop) {
        // User has scrolled passed table
        if (this.table.hasClass('top-fixed')) {
            this.table.removeClass('top-fixed').addClass('top-scrolling');
        }
    } else if (this.table.hasClass('top-fixed')) {
        this.table.removeClass('top-fixed top-scrolling');
        this.productsTopInfo.attr('style', '');
        this.topBackground.attr('style', '');
    }
    if (offsetTop < scrollTop + 300 && this.showAnimatingHand === true) {
        this.showAnimatingHand = false;
        this.table.addClass('hide-animating-hand');
    }
};

ProductsTable.prototype.updateProperties = function updateProperties() {
    this.tableHeight = this.table.height();
    this.topInfoHeight = this.featuresTopInfo.innerHeight();
    this.menuHeight = 0;
    var clientRect;
    if (window.innerWidth < 1024) {
        // Mobile
        clientRect = document.querySelector('.main-nav--mobile').getBoundingClientRect();
    } else {
        clientRect = document.querySelector('.main-nav--desktop').getBoundingClientRect();
    }
    this.menuHeight = clientRect.top + clientRect.height;
};

var windowScrolling = false;
//detect window scroll - fix product top-info on scrolling
$(window).on('scroll', function () {
    if (!windowScrolling) {
        windowScrolling = true;
        if (!window.requestAnimationFrame) {
            setTimeout(checkScrolling, 150);
        } else {
            window.requestAnimationFrame(checkScrolling);
        }
    }
});

var windowResize = false;
//detect window resize - reset .comparison-section properties
$(window).on('resize', function () {
    if (!windowResize) {
        windowResize = true;
        if (!window.requestAnimationFrame) {
            setTimeout(checkResize, 250);
        } else {
            window.requestAnimationFrame(checkResize);
        }
    }
});

function checkScrolling() {
    var scrollTop = window.pageYOffset;
    comparisonTables.forEach(function (element) {
        element.updateTopScrolling(scrollTop);
    });

    windowScrolling = false;
}

function checkResize() {
    comparisonTables.forEach(function (element) {
        element.updateProperties();
    });

    windowResize = false;
}

function setTranformY(element, value) {
    element.css({
        transform: 'translateY(' + value + 'px)',
    });
}

function toggleMultiSelect(ev) {
    if ($(ev.currentTarget).attr('aria-expanded') === 'true') {
        closeMultiSelect(ev.currentTarget);
    } else {
        openMultiSelect(ev.currentTarget);
    }
}

function openMultiSelect(button) {
    $(button).attr('aria-expanded', 'true');
}

function closeMultiSelect(button) {
    $(button).attr('aria-expanded', 'false');
}
