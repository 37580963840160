export function vacationaddress($myBlockIdentifier) {
    $(function () {
        var myIdentifier = $myBlockIdentifier.prop('id').replace(/-/g, '');

        auto.completeaddress(
            $('[name="' + myIdentifier + '_StreetName"]').prop('id'),
            $('[name="' + myIdentifier + '_HouseNumber"]').prop('id'),
            $('[name="' + myIdentifier + '_HouseFloor"]').prop('id'),
            $('[name="' + myIdentifier + '_HouseDoorSide"]').prop('id'),
            $('[name="' + myIdentifier + '_PostalCode"]').prop('id'),
            $('[name="' + myIdentifier + '_City"]').prop('id'),
        );

        auto.completecity(
            $('[name="' + myIdentifier + '_PostalCode"]').prop('id'),
            $('[name="' + myIdentifier + '_City"]').prop('id'),
        );

        $('.read-only').find('input, textarea, select').attr('readonly', 'readonly');
    });
}
