import { init as initCookiesConsent } from './cookies-consent';

function main() {
    $(() => {
        var globalEventHandlers = () => {
            // Click function for mobile devices for Iphones and Ipads
            $('body').on('touchstart', '*', function onTouch() {
                //listen to touch
                var jQueryElement = $(this);
                var element = jQueryElement.get(0); // find tapped HTML element
                if (!element.click) {
                    var eventObj = document.createEvent('MouseEvents');
                    eventObj.initEvent('click', true, true);
                    element.dispatchEvent(eventObj);
                }
            });

            $('.removelink-onload').each(function removeHref() {
                $(this).removeAttr('href');
            });
        };

        var globalJQueryExtensions = () => {
            jQuery.fn.singleClick = function onSingleClick(func) {
                $(this).on('click', function onClick(e) {
                    var $el = $(this);
                    if ($el.data('clicked')) {
                        // Previously clicked, stop actions
                        e.preventDefault();
                        e.stopPropagation();
                    } else {
                        // Mark to ignore next click
                        $el.data('clicked', true);

                        // Callback function
                        func(e);

                        // Unmark after 1 second
                        window.setTimeout(function () {
                            $el.removeData('clicked');
                        }, 200);
                    }
                });

                return this;
            };
        };

        var globalInit = () => {
            $(document).foundation('interchange', 'reflow');
            $(document).foundation('equalizer', 'reflow');
            $(document).foundation('tab', 'reflow');
            $(document).foundation('reveal', 'reflow');
            $(document).foundation('dropdown', 'reflow');

            initCookiesConsent();

            /**
             * Make tabs into slider on mobile phones
             */
            $('.dotdotdot-wrapper').dotdotdot({ watch: true });

            if ('addEventListener' in document) {
                document.addEventListener(
                    'DOMContentLoaded',
                    function () {
                        // eslint-disable-next-line no-undef
                        FastClick.attach(document.body);
                    },
                    false,
                );
            }
        };

        const getCookie = (cookieName) => {
            let cookie = {};

            document.cookie.split(';').forEach(function (el) {
                const [key, value] = el.split('=');

                cookie[key.trim()] = value;
            });

            return cookie[cookieName];
        };

        var smoothScroll = () => {
            function scrollToId() {
                const isCookieConsentSet = getCookie('CookieInformationConsent');
                const hash = window.location.hash.slice(1);

                if (!hash) {
                    return;
                }

                const animateScroll = () =>
                    $('html, body').animate(
                        {
                            scrollTop: $('#js-' + hash).offset().top - 140, //Top nav height
                        },
                        1000,
                    );

                if (window.location.hash && $('#js-' + hash).length && isCookieConsentSet) {
                    return animateScroll();
                }

                window.addEventListener('CookieInformationConsentGiven', animateScroll);

                return null;
            }

            scrollToId();

            $('a[href]:not([role=tab])').on('click', function onClick() {
                var link = $(this).attr('href');
                if (link.indexOf('#') >= 0 && link.length > 1) {
                    window.location.href = link;

                    scrollToId();

                    return false;
                }

                return true;
            });

            $('.js-smoothscroll').parents('.main-section').css('margin', '0px');
        };

        // SleekNote Autocomplete
        if (window.MutationObserver) {
            var sleekNoteObserver;
            var bodyObserver;

            var sleekNoteAutocomplete = ($iframe) => {
                sleekNoteObserver.disconnect();

                var $iframeContent = $($iframe.contents());

                var autocompleteStyles =
                    '.ui-helper-hidden-accessible { display: none; }' +
                    '.ui-widget-content { border: 1px solid #ddd; background: #fff; color: #333; }' +
                    '.ui-menu .ui-menu-item:not(:last-child) { border-bottom: 1px solid #ccc; }' +
                    '.ui-menu .ui-menu-item { position: relative; margin: 0; cursor: pointer; min-height: 0; color: #aaa; }' +
                    '.ui-menu .ui-menu-item a { display: block; padding: 10px; color: inherit; }' +
                    '.ui-state-focus { background: #eaf0f2; font-weight: normal; color: #212121; }' +
                    '.ui-menu .ui-menu-item.ui-state-focus a { color: #212121; }';

                $('<style>' + autocompleteStyles + '</style>').appendTo($iframeContent.find('head'));

                var $inputCompanyName = $iframeContent.find('input[name=company]');
                var $inputPostal = $iframeContent.find('input[name=zipCode]');
                var $inputTDCId = $iframeContent.find('input[name=tdcid]');

                $inputCompanyName.autocomplete({
                    //appendTo: $inputCompanyName.parent(),
                    position: { collision: 'flip' },
                    source: function (request, response) {
                        var urlTerm = 'https://mdbprodssl.falck.com/api/ErhvervData/Get?name=' + request.term;
                        if ($inputPostal.val() !== '') {
                            urlTerm =
                                'https://mdbprodssl.falck.com/api/ErhvervData/Get?name=' +
                                $inputPostal.val() +
                                ' ' +
                                request.term;
                        }

                        $.get(urlTerm, function (data) {
                            response(
                                $.map(data, function (item) {
                                    return {
                                        label: item.DisplayText,
                                        value: item,
                                    };
                                }),
                            );
                        });
                    },
                    focus: function checkoutAutoFill(event, ui) {
                        var account = ui.item.value;

                        $inputTDCId.val(account.TDCId);
                        $inputCompanyName.val(account.AccountName);

                        event.preventDefault();
                    },
                    select: function checkoutAutoFill(event, ui) {
                        var account = ui.item.value;

                        $inputTDCId.val(account.TDCId);
                        $inputCompanyName.val(account.AccountName);

                        event.preventDefault();
                    },
                });

                $inputCompanyName.data('ui-autocomplete')._renderItem = (ul, item) => {
                    return $('<li>')
                        .append('<a href="#">' + item.label + '</a>')
                        .appendTo(ul);
                };

                $inputCompanyName.data('ui-autocomplete')._resizeMenu = function onResize() {
                    var ul = this.menu.element;
                    ul.outerWidth(this.element.outerWidth());
                };

                $inputCompanyName.on('change', function onChange() {
                    if (!$(this).val()) {
                        $inputTDCId.val('');
                    }
                });
            };

            var sleekNoteObserverCallback = (mutationsList) => {
                bodyObserver.disconnect();

                for (var i = 0; i < mutationsList.length; i++) {
                    var $sleekNoteForm = $('[id^=form-] iframe');

                    if ($sleekNoteForm.length) {
                        sleekNoteAutocomplete($sleekNoteForm);
                        break;
                    }
                }
            };

            var bodyObserverCallback = (mutationsList) => {
                mutationsList.forEach(function (mutation) {
                    if (!mutation.addedNodes[0] || mutation.addedNodes[0].id !== 'sleeknote') {
                        return;
                    }

                    sleekNoteObserver = new window.MutationObserver(sleekNoteObserverCallback);
                    sleekNoteObserver.observe(mutation.addedNodes[0], { childList: true });
                });
            };

            bodyObserver = new window.MutationObserver(bodyObserverCallback);
            bodyObserver.observe(document.body, { childList: true });
            setTimeout(function () {
                bodyObserver.disconnect();
            }, 10000);
        }

        // Start load of global event handlers, global JQuery extensions and global init
        globalEventHandlers();
        globalJQueryExtensions();
        globalInit();
        smoothScroll();
    });
}

export default main();
