// Description:
//define('checkout-form-footer', ['slickslider'],
//used??
export function checkout_form_footer() {
    $(() => {
        const $checkoutBack = $('.js-checkout-btn-back');
        const $checkoutFormSubmit = $('.js-checkout-btn-submit');
        const $checkoutForm = $('form.checkout-form');

        console.log($checkoutForm);

        $checkoutBack.on('click', function onBack() {
            $(this).addClass('is-loading');
        });

        if (!$checkoutForm.length) {
            $checkoutFormSubmit.on('click', function onSubmit() {
                $(this).addClass('is-loading');
            });
        }

        $checkoutForm.on('submit', function onForm(e) {
            // There could be many forms on one page (like payment), so needs to be retrieved by ID
            let currentForm = $('#' + e.target.id);
            let validations = currentForm.find('input, select, textarea');
            var filteredValidations = validations.toArray().filter((item) => !item.hasAttribute('data-abide-ignore'));

            Foundation.libs.abide.validate(filteredValidations, { type: '' });

            if (
                !currentForm.find('[data-invalid]').length &&
                (window.isCarInfoReceived === undefined || window.isCarInfoReceived)
            ) {
                $checkoutFormSubmit.addClass('is-loading');
            } else {
                e.stopPropagation();
                e.preventDefault();
            }
        });
    });
}
