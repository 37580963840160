// Description: Vehicle view for checkout flow
//define('vehicle-information',
// ['foundation', 'form-validation']
//, function () {
// function init($myBlockIdentifier, isCarInfoReceived) {
//     return new _vehicle_information($myBlockIdentifier, isCarInfoReceived);
// }

export function vehicle_information($myBlockIdentifier, isCarInfoReceived) {
    $(() => {
        var form = document.querySelector('#productInformationForm');

        var licenseplate = $myBlockIdentifier[0].querySelector('.js-checkout-form-licenseplate');
        var licenseplateInput = licenseplate.querySelector('input');
        var licenseplateBtn = licenseplate.querySelector('button');

        window.isCarInfoReceived = isCarInfoReceived;

        var brand = $myBlockIdentifier[0].querySelector('.js-brand');
        var model = $myBlockIdentifier[0].querySelector('.js-model');
        var year = $myBlockIdentifier[0].querySelector('.js-year');
        var colour = $myBlockIdentifier[0].querySelector('.js-colour');

        var yesNoModal = form.querySelector('.js-vehicle-information-modal');
        var yesNoModalBtn = yesNoModal.querySelector('.js-vehicle-information-modal-yes');

        $(document).foundation(
            {
                abide: {
                    live_validate: false,
                    validate_on_blur: false,
                },
            },
            'reflow',
        );

        function removeAbideError(input) {
            var inputParent = input.parentNode;
            inputParent.classList.remove('error');
            inputParent.removeAttribute('role');
            input.removeAttribute('data-invalid');
            inputParent.parentNode.classList.remove('error');
        }

        async function validateLicenseplate() {
            Foundation.libs.abide.validate([licenseplateInput], { type: '' });

            var isLicensePlateValid = !licenseplateInput.parentNode.classList.contains('error');

            if (isLicensePlateValid) {
                licenseplateBtn.classList.add('is-loading');

                await fetch(`/api/Cars/FindPlate?plate=${licenseplateInput.value}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                })
                    .then((response) => {
                        if (response.status === 404) {
                            $(yesNoModal).foundation('reveal', 'open');
                        }

                        return response.json();
                    })
                    .then((data) => {
                        brand.textContent = data.Brand;
                        document.getElementById('brand').value = data.Brand;
                        model.textContent = data.Model;
                        document.getElementById('model').value = data.Model;

                        const yearNUm = data.Year?.match(/\d+/g)
                        if (!!yearNUm) {
                            year.textContent = yearNUm;
                        }
                        document.getElementById('year').value = yearNUm;

                        colour.textContent = data.Color;
                        document.getElementById('color').value = data.Color;
                        window.isCarInfoReceived = true;
                    })
                    .catch((error) => {
                        brand.textContent = '';
                        model.textContent = '';
                        year.textContent = '';
                        colour.textContent = '';
                        window.isCarInfoReceived = false;
                        console.error('There has been a problem with your fetch operation:', error);
                    })
                    .finally(() => {
                        licenseplateBtn.classList.remove('is-loading');
                    });
            } else {
                licenseplateBtn.classList.remove('is-loading');
            }

            return isLicensePlateValid;
        }

        licenseplateInput.addEventListener('click', function () {
            if (window.matchMedia('(max-width: 640px)').matches) {
                $('html, body').animate({ scrollTop: $(licenseplate).offset().top - 80 }, 0);
            }
        });

        licenseplateInput.addEventListener('input', function onInput() {
            removeAbideError(this);
        });

        licenseplateBtn.addEventListener('click', validateLicenseplate);

        licenseplateInput.addEventListener('keydown', function (event) {
            if (event.key === 'Enter') {
                event.preventDefault();

                validateLicenseplate();
            }
        });

        var validations = $myBlockIdentifier[0].querySelectorAll('input, select, textarea');

        form.addEventListener('submit', async function (e) {
            e.preventDefault();

            Foundation.libs.abide.validate(validations, { type: '' });

            var isInvalidLicenseplate = licenseplateInput.parentNode.classList.contains('error');

            if (!isInvalidLicenseplate) {
                if (window.isCarInfoReceived) {
                    $(form).submit();
                } else {
                    $(yesNoModal).foundation('reveal', 'open');
                }
            }
        });

        yesNoModalBtn.addEventListener('click', function () {
            $(yesNoModal).foundation('reveal', 'close');
        });
    });
}
