// Description: Customer Information view for checkout flow
import { completeaddress } from './../common/autocomplet';

export function customer_information(myIdentifier) {
    $(function () {
        completeaddress(
            getIdByName('SearchAd', myIdentifier),
            getIdByName('AccountAddressId', myIdentifier),
            getIdByName('AccountAddressStreetName', myIdentifier),
            getIdByName('AccountAddressHouseNumber', myIdentifier),
            getIdByName('AccountAddressFloor', myIdentifier),
            getIdByName('AccountAddressDoorSide', myIdentifier),
            getIdByName('AccountAddressPostalCode', myIdentifier),
            getIdByName('AccountAddressCity', myIdentifier),
        );

        $('.read-only').find('input, textarea, select').attr('readonly', 'readonly').attr('tabindex', '-1');
    });
}

export function add_falck_account_no_validator(){
    const radioButtons = document.querySelectorAll('input[name="IsCustomer"]');
    const falckAccount = document.querySelectorAll('[name$="FalckAccountNumber"]')[0];
    const Required = 'required';
    
    radioButtons.forEach((radio) => {
        radio.addEventListener('change', (event) => {
            console.warn(event)
            if (event.target.value == true){
                console.warn('set')
                falckAccount.setAttribute(Required, true)
            } else {
                falckAccount.removeAttribute(Required);
                falckAccount.value = '';
            }
        });
    });
}

// Gets the DOM element's id by its name.
function getIdByName(name, guid) {
    const guidNoDash = guid.replace(/-/g, ''); // Remove '-'

    return $('[name="' + guidNoDash + '_' + name + '"]').prop('id');
}
