/*
 * Utilities used to control visibility and behaviour of blocks that rely on cookie consent
 * Mainly toggling visibility of a block / fallback text when user accepts / rejects cookies
 */
export const COOKIE_CATEGORIES = Object.freeze({
    FUNCTIONAL: 'cookie_cat_functional',
    STATISTIC: 'cookie_cat_statistic',
    MARKETING: 'cookie_cat_marketing',
});

var COOKIE_CATEGORIES_VALUES = Object.keys(COOKIE_CATEGORIES).map(function (cat) {
    return COOKIE_CATEGORIES[cat];
});

export const CONSENT_CHANGE_EVENT = 'CookieInformationConsentGiven';

var VISIBILITY_CONTROL_CLASS_NAME = 'visible';

var YOUTUBE_FALLBACK_TEXT_CLASS_NAME = 'video-cookie-fallback-text';

var IFRAME_FALLBACK_TEXT_CLASS_NAME = 'iframe-fallback-visibility';

var MAPS_FALLBACK_CLASS_NAME = 'googlemaps-fallback-visibility';

var OPEN_COOKIE_CONSENT_MODAL_ATTRIBUTE = 'openCookieConsentModal';

var fallbackTextsVisibilityConfig = [
    {
        domNodesClassName: YOUTUBE_FALLBACK_TEXT_CLASS_NAME,
        cookieCategories: [COOKIE_CATEGORIES.MARKETING],
    },
    {
        domNodesClassName: MAPS_FALLBACK_CLASS_NAME,
        cookieCategories: [COOKIE_CATEGORIES.MARKETING],
    },
    {
        domNodesClassName: IFRAME_FALLBACK_TEXT_CLASS_NAME,
        cookieCategories: COOKIE_CATEGORIES_VALUES,
    },
];

init();

export function init() {
    setupPageReloadOnConsentChange();
    setupCookieConsentFallbackOptions();
}
export function forceReloadOnCookieConsentChange() {
    if (window.CookieInformation) {
        window.CookieInformation.ForceReloadOnConsentChange = true;
    }
}
function shouldReloadOnConsentChange() {
    return !!(window && window.CookieInformation && window.CookieInformation.ForceReloadOnConsentChange);
}
function consentChangeRequestedByUser(clickedNode) {
    var onClickFunctionName =
        clickedNode.attributes && clickedNode.attributes.onclick && clickedNode.attributes.onclick.value;
    return [
        'CookieInformation.submitConsent',
        'CookieInformation.declineAllCategories',
        'CookieInformation.submitAllCategories',
    ].some(function (functionChangingConsent) {
        return String(onClickFunctionName).indexOf(functionChangingConsent) > -1;
    });
}
function setupPageReloadOnConsentChange() {
    window.addEventListener('click', function (event) {
        if (consentChangeRequestedByUser(event.target) && shouldReloadOnConsentChange()) {
            window.location.reload(true);
        }
    });
}
function setupCookieConsentFallbackOptions() {
    var setFallbackTextVisibility;
    if (window && window.CookieInformation) {
        fallbackTextsVisibilityConfig.forEach(function (fallbackTextCategory) {
            setFallbackTextVisibility = setFallbackTextVisibilityForNodes(
                getFallbackTextNodes(fallbackTextCategory.domNodesClassName),
                fallbackTextCategory.cookieCategories,
            );
            setFallbackTextVisibility();
            window.addEventListener(CONSENT_CHANGE_EVENT, setFallbackTextVisibility);
        });
        setupOpeningCookieModalOnPlaceholderLinkClick();
    }
}
function setupOpeningCookieModalOnPlaceholderLinkClick() {
    window.addEventListener('click', function (event) {
        if (event.target && event.target.hasAttribute(OPEN_COOKIE_CONSENT_MODAL_ATTRIBUTE) && window.showCookieBanner) {
            window.showCookieBanner();
        }
    });
}

export function getCookieConsentValue(cookieCategory) {
    return window.CookieInformation.getConsentGivenFor(cookieCategory);
}

export function areAllCookiesAccepted() {
    return areSelectedCookiesAccepted(COOKIE_CATEGORIES_VALUES);
}

function areSelectedCookiesAccepted(cookiesArray) {
    return cookiesArray.every((category) => {
        return getCookieConsentValue(category);
    });
}

export function getFallbackTextNodes(nodesClassName) {
    return Array.prototype.slice.call(document.querySelectorAll('.' + nodesClassName));
}

export function setFallbackTextVisibilityForNodes(fallbackTextNodes, cookieCategories) {
    return () => {
        if (areSelectedCookiesAccepted(cookieCategories)) {
            fallbackTextNodes.forEach(function (textNode) {
                textNode.classList.remove(VISIBILITY_CONTROL_CLASS_NAME);
            });
        } else {
            fallbackTextNodes.forEach(function (textNode) {
                textNode.classList.add(VISIBILITY_CONTROL_CLASS_NAME);
            });
        }
    };
}
