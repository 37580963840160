// Description: Uses google api to draw interactive map.
export function google_maps(
    $myBlockIdentifier,
    myFalckLocations,
    myStartLatitude,
    myStartLongitude,
    myDirectionsText,
    myCountryCode,
    myMarkIcon,
    myYouAreHereIcon,
    mySearchPinIcon,
    myCannotFindYourLocationText,
    myCannotFindAddressText,
) {
    return window && window.google && window.google.maps
        ? new _google_maps(
              $myBlockIdentifier,
              myFalckLocations,
              myStartLatitude,
              myStartLongitude,
              myDirectionsText,
              myCountryCode,
              myMarkIcon,
              myYouAreHereIcon,
              mySearchPinIcon,
              myCannotFindYourLocationText,
              myCannotFindAddressText,
          )
        : '';
}

function _google_maps(
    $myBlockIdentifier,
    myFalckLocations,
    myStartLatitude,
    myStartLongitude,
    myDirectionsText,
    myCountryCode,
    myMarkIcon,
    myYouAreHereIcon,
    mySearchPinIcon,
    myCannotFindYourLocationText,
    myCannotFindAddressText,
) {
    const google = window.google;

    var STARTZOOM = 6;
    var USECLUSTERONPAGE = 0;
    var INFOWINDOWMAXWIDTHOPTION = 350; // This always ended up being 350 in old code, as code logic did not work

    var $blockIdentifier = $myBlockIdentifier;
    var falckLocations = myFalckLocations;
    var startLatitude = myStartLatitude;
    var startLongitude = myStartLongitude;
    var directionsText = myDirectionsText;
    var countryCode = myCountryCode;
    var markIcon = myMarkIcon;
    var youAreHereIcon = myYouAreHereIcon;
    var searchPinIcon = mySearchPinIcon;
    var cannotFindYourLocationText = myCannotFindYourLocationText;
    var cannotFindAddressText = myCannotFindAddressText;

    var browserSupportFlag = false;
    var mymap = null;
    var currentInfoWindow = null;
    var currentlocation = null;

    function _init() {
        mymap = new MapObj();
        mymap.initializeMap();
    }

    function MapObj() {
        this.map = null;
        this.infotextset = false;
        this.geocoder = null;
        this.markers = [];
        this.myCurrentPositionMarker = null;
        this.markerClusterer = null;
        this.initialLocation = null;

        this.initializeMap = function onInit() {
            this.initialLocation = new google.maps.LatLng(startLatitude, startLongitude);
            var mapOptions = {
                zoom: STARTZOOM,
                center: this.initialLocation,
                streetViewControl: false,
                mapTypeControl: true,
                overviewMapControl: true,
                overviewMapControlOptions: { opened: true },
                mapTypeControlOptions: {
                    mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.SATELLITE],
                    style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                },
                navigationControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
            };

            this.map = new google.maps.Map($($blockIdentifier).find('.googlemaps-canvas')[0], mapOptions);
            this.insertMarkers();

            var input = $($blockIdentifier).find('.googlemaps-searchbox')[0];
            var options = {
                types: ['geocode'],
                componentRestrictions: { country: countryCode },
            };
            var autocomplete = new google.maps.places.Autocomplete(input, options);
            autocomplete.bindTo('bounds', this.map);

            this.panToMe();
        };

        this.checkMarkers = () => {
            /*if (UseClusterOnPage == '0') {
                    jQuery.each(mymap.markers, function (index, value) {
                        if (mymap.map.getBounds().contains(value.getPosition())) {
                            value.setMap(mymap.map);
                        }
                        else {
                            value.setMap(null);
                        }
                    });
                }*/
        };

        $($blockIdentifier.find('.googlemaps-refresh-map')).on('click', function () {
            return mymap.panToMe();
        });

        $($blockIdentifier.find('.googlemaps-find-address')).on('click', function () {
            return mymap.findAddress();
        });

        $($blockIdentifier.find('.googlemaps-search-reset')).on('click', function () {
            return mymap.searchReset();
        });

        $($blockIdentifier)
            .find('.googlemaps-searchbox')
            .keypress(function (event) {
                if (event.which === 13) {
                    mymap.findAddress();
                }
            });

        this.panToMe = () => {
            if (window.navigator.geolocation) {
                browserSupportFlag = true;
                window.navigator.geolocation.getCurrentPosition(
                    function (position) {
                        currentlocation = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                        mymap.map.setCenter(currentlocation);
                        mymap.map.setZoom(10);

                        mymap.myCurrentPositionMarker = new google.maps.Marker({
                            title: '',
                            icon: youAreHereIcon,
                            map: mymap.map,
                            position: currentlocation,
                        });

                        mymap.myCurrentPositionMarker.isLocation = false;
                    },
                    function () {
                        $('.MeImage').hide();
                        browserSupportFlag = false;
                        handleNoGeolocation(browserSupportFlag);
                    },
                );
            } else {
                // Browser doesn't support Geolocation
                $('.MeImage').hide();
                browserSupportFlag = false;
                handleNoGeolocation(browserSupportFlag);
            }

            return false;
        };

        this.searchReset = function onReset() {
            $($blockIdentifier).find('.googlemaps-searchbox').val('');

            this.map.setZoom(STARTZOOM);
            this.removeLocationMarkers();
            this.map.setCenter(mymap.initialLocation);

            if (currentInfoWindow) currentInfoWindow.close();

            return false;
        };

        this.findAddress = function onFindAddress() {
            if (!this.geocoder) {
                this.geocoder = new google.maps.Geocoder();
            }

            this.geocoder.geocode(
                {
                    address: $($blockIdentifier).find('.googlemaps-searchbox').val(),
                    region: countryCode,
                },
                function (results, status) {
                    if (status === google.maps.GeocoderStatus.OK) {
                        mymap.handlerAddresses(results);
                    } else {
                        errorGeoCallBack(status);
                    }
                },
            );

            return false;
        };

        this.findNearest = function onFindNearest(geoResult) {
            var distance = 100000;
            var bestbet = null;
            var lat = geoResult.geometry.location.lat();
            var lng = geoResult.geometry.location.lng();
            var R = 6371; // radius of earth in km

            $.each(mymap.markers, function (index, value) {
                if (value.isLocation === false) {
                    var mlat = value.position.lat();
                    var mlng = value.position.lng();
                    var dLat = rad(mlat - lat);
                    var dLong = rad(mlng - lng);
                    var a =
                        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                        Math.cos(rad(lat)) * Math.cos(rad(lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
                    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                    var d = R * c;

                    if (d < distance) {
                        distance = d;
                        bestbet = value;
                    }
                }
            });

            if (bestbet !== null) {
                this.map.setZoom(12);
                mymap.map.setCenter(bestbet.position);
                if (mymap.map.getBounds().contains(bestbet.position) === false) {
                    this.map.setZoom(10);
                }
                mymap.showInfo(bestbet);
            }
        };

        this.removeLocationMarkers = () => {
            mymap.markers = $.grep(mymap.markers, function (value) {
                if (value.isLocation === true) {
                    value.setMap(null);
                    return false;
                } else return true;
            });
        };

        this.handlerAddresses = function onHandler(geocoderResults) {
            mymap.map.setZoom(mymap.map.getZoom());
            this.removeLocationMarkers();

            if (geocoderResults.length > 0) {
                var geoResult = geocoderResults[0];
                var location = geoResult.geometry.location;
                var marker = new google.maps.Marker({
                    title: geoResult.formatted_address,
                    position: location,
                    map: mymap.map,
                    icon: searchPinIcon,
                });
                marker.isLocation = true;
                mymap.markers.push(marker);

                this.findNearest(geoResult);
            }
        };

        this.insertMarkers = function onInsert() {
            $.each(falckLocations, this.insertSingleImageOnMap);

            if (USECLUSTERONPAGE === '1') {
                // eslint-disable-next-line no-undef
                this.markerClusterer = new MarkerClusterer(this.map, this.markers, { maxZoom: 9, gridSize: 35 });
            }
        };

        this.insertSingleImageOnMap = (index, value) => {
            var marker = new google.maps.Marker({
                title: decodeURIComponent(value.Titel),
                icon: markIcon,
                position: new google.maps.LatLng(value.YC, value.XC),
                map: mymap.map,
            });

            marker.infoText = formatInfoText(value);
            marker.isLocation = false;
            marker.SId = value.SId;

            google.maps.event.addListener(marker, 'click', function () {
                if (currentInfoWindow) {
                    currentInfoWindow.close();
                } else {
                    currentInfoWindow = new google.maps.InfoWindow({ maxWidth: INFOWINDOWMAXWIDTHOPTION });
                }
                currentInfoWindow.setContent(marker.infoText);
                currentInfoWindow.open(mymap.map, marker);
            });

            mymap.markers.push(marker);
        };

        this.showInfo = (marker) => {
            if (currentInfoWindow) {
                currentInfoWindow.close();
            } else {
                currentInfoWindow = new google.maps.InfoWindow({ maxWidth: INFOWINDOWMAXWIDTHOPTION });
            }
            currentInfoWindow.setContent(marker.infoText);
            currentInfoWindow.open(mymap.map, marker);
        };
    }

    function rad(x) {
        return x * (Math.PI / 180);
    }

    function formatInfoText(entry) {
        var name = entry.Titel !== '' ? entry.Titel : entry.StreetName;
        var address = entry.StreetName + ' ' + entry.StreetNumber + '<br />' + entry.PostalCode + '&nbsp;' + entry.City;
        var about = entry.Info + '<br/> ' + entry.Services;
        var directionsLink =
            '<a href="http://maps.google.com/?{Start}daddr=' +
            entry.YC +
            ',' +
            entry.XC +
            '" target="_blank">' +
            directionsText +
            '</a>';
        var moremaplink = '';

        if (entry.MapMoreLink !== undefined && entry.MapMoreLink !== null && entry.MapMoreLink !== '') {
            moremaplink =
                '<p class="googlemaps-button"><a href="' +
                entry.MapMoreLink.split('#')[0] +
                '" target="' +
                entry.MapMoreLink.split('#')[1] +
                '"></a></p>';
        }

        var infoTextTemplate =
            '<div class="infoWindow"><p class="header">{header}</p><p class="address">{address}</p><p class="About">{About}</p><div class="row">' +
            moremaplink +
            '<p>{directionsLink}</p></div><div class="closeLinkBg"></div></div>';
        var infoText = infoTextTemplate
            .replace('{About}', about)
            .replace('{header}', name)
            .replace('{address}', address)
            .replace('{directionsLink}', directionsLink);

        if (browserSupportFlag)
            infoText = infoText.replace(
                '{Start}',
                'saddr=' + currentlocation.lat() + ',' + currentlocation.lng() + '&',
            );
        else infoText = infoText.replace('{Start}', '');

        return infoText;
    }

    function handleNoGeolocation() {
        showMessage(cannotFindYourLocationText);
    }

    function errorGeoCallBack() {
        showMessage(cannotFindAddressText);
    }

    function showMessage() {
        //alert(txt);
    }

    _init();
}
