export function avoid_spaces_input($inputIdentifier) {
    $(() => {
        $inputIdentifier.on('keypress', function (event) {
            if (event.key == ' ') {
                event.preventDefault();
                return false;
            }
        });

        $inputIdentifier.on('blur cut copy paste', function (event) {
            if ($(this).val().indexOf(' ') > -1) {
                let newInputValue = $(this).val().replaceAll(' ', '');
                $(this).val(newInputValue);
            }
        });
    });
}
