// Description: validates pattern

export function filter_input_add_validation($form) {
    $(() => {
        var inputFields = $form.find("input[pattern != ''][pattern]");
        inputFields.on('input keydown keyup mousedown mouseup select contextmenu drop', function onInput() {
            var pattern = $(this).attr('pattern');
            var regexp = new RegExp(pattern);

            if (regexp.test(this.value)) {
                this.oldValue = this.value;
                this.oldSelectionStart = this.selectionStart;
                this.oldSelectionEnd = this.selectionEnd;
            } else if (Object.prototype.hasOwnProperty.call(this, 'oldValue')) {
                this.value = this.oldValue;
                this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
            }
        });
    });
}
