////////////////////////////////////////////////////////
// ================================================== //
// Description: Subscription wizard                   //
// ================================================== //
////////////////////////////////////////////////////////

export function init_subscription(elm) {
    var subs = {
        elm: elm,
        lists: $('[data-list]', elm),
        toggle: $('[data-toggle]', elm),
        toggleOptions: $('[data-target]', elm),
        subOptions: $('[data-option]', elm),

        initLists: function () {
            var _self = this;

            if (this.lists.length > 1) {
                this.lists.each(function onList() {
                    var list = $(this);
                    var listName = list.data('list');

                    var option = $('<div>')
                        .addClass('subs__toggle-item')
                        .attr('data-target', listName)
                        .text(listName)
                        .appendTo(_self.toggle);

                    _self.toggleOptions = _self.toggleOptions.add(option);

                    option.on('click', () => {
                        _self.lists.removeClass('is-active');
                        list.addClass('is-active');
                        _self.toggleOptions.removeClass('is-active');
                        option.addClass('is-active');
                    });
                });
            } else {
                $(this.lists[0]).addClass('is-active');
                $(this.toggle).hide();
            }

            this.initSelfService();
        },
        initSelfService: function () {
            var _self = this;
            var openText = $('.subscription__data-details--top .subscription-details-text', elm).first().text();
            var closeText = $('.subscription__data-details--bottom .subscription-details-text', elm).first().text();

            $('.js-subscription-details-link', elm).on('click', function toggleSubscriptionDetails() {
                $('.subscription__data-details--top .subscription-expandlist-arrow', elm).toggleClass(
                    'subscription-expandlist-arrow--collapse',
                );
                $('.subscription__data-list--expandable', elm).fadeToggle(toggleCallback);
                $('.subscription__data-details--bottom', elm).fadeToggle();

                function toggleCallback() {
                    var toggleText = $(this).is(':visible') ? closeText : openText;
                    $('.subscription__data-details--top .subscription-details-text', $(this).parent()).text(toggleText);
                }

                return false;
            });

            $('.js-service-details-link', elm).on('click', function toggleServiceDetails() {
                $('.service-details-content', $(this).closest('li')).slideToggle(iconCallback);
                $('.service-details-content', elm)
                    .not($('.service-details-content', $(this).closest('li')))
                    .slideUp(iconCallback);

                function iconCallback() {
                    var iconClass = $(this).is(':visible') ? 'service-expandlist-arrow--collapse' : '';
                    $('.service-expandlist-arrow', $(this).closest('li'))
                        .removeClass('service-expandlist-arrow--collapse')
                        .addClass(iconClass);
                }

                return false;
            });

            _self.initListsSlider(false);
        },
        initListsSlider: function (equalHeightServices) {
            const stickyify = (destroy) => {
                var $nav = $('.owl-nav');
                if (destroy) $nav.stickyfloat('destroy');
                $nav.stickyfloat({ offsetY: 90, duration: 100 });
            };

            this.lists.each(function onCarousel() {
                var $list = $(this),
                    $carousel = $('.js-subs-carousel', $list),
                    itemCount = $list.find('.subs__list-item').length,
                    active = $carousel.parent().attr('data-active'),
                    startPosition = Number.isNaN(parseInt(active, 10)) ? 0 : parseInt(active, 10) - 1,
                    options = {
                        navElement: 'div',
                        dotElement: 'div',
                        responsive: {
                            // breakpoint from 0 up
                            0: {
                                items: 1,
                                margin: 15,
                                loop: false,
                                mouseDrag: true,
                                touchDrag: true,
                                nav: false,
                                navText: ['', ''],
                                startPosition: startPosition,
                            },
                            // breakpoint from 1024 up
                            660: {
                                items: itemCount,
                                margin: 15,
                                autoWidth: true,
                                loop: false,
                                mouseDrag: false,
                                touchDrag: true,
                                nav: false,
                                dots: false,
                                navText: ['', ''],
                                startPosition: startPosition,
                            },
                            // breakpoint from 1024 up
                            1024: {
                                items: itemCount,
                                margin: 20,
                                autoWidth: true,
                                loop: false,
                                mouseDrag: false,
                                touchDrag: true,
                                nav: false,
                                dots: false,
                                navText: ['', ''],
                                startPosition: startPosition,
                            },
                        },

                        onInitialized: function () {
                            stickyify();
                        },
                        onRefreshed: function () {
                            stickyify(true);
                        },
                        onResized: function () {
                            stickyify(true);
                        },
                    };

                // initialize carousel
                $carousel.owlCarousel(options);
                $list.addClass('is-loaded');
            });
        },
        initOptions: function () {
            this.subOptions.each(function onOption() {
                var option = $(this);
                var checkbox = $('[type="checkbox"]', option);
                if (checkbox.prop('checked')) {
                    option.addClass('selected');
                }
                checkbox.on('change', function onClick(e) {
                    option.toggleClass('selected');
                    $(this).data('select', !$(this).data('select'));
                    checkbox.prop('checked', $(this).data('select'));
                });
            });
        },

        addToBasketAnimation: function () {
            $('.main-section--subscriptions').on(
                'touchstart click',
                ".subscription__btn[type='submit']",
                function onSubmit() {
                    var $icon = $(this).find('.icon'),
                        iconCss = $icon.attr('class'),
                        animateCss = ' animated tada';

                    $icon.attr('class', iconCss + animateCss);
                    setTimeout(function () {
                        $icon.attr('class', iconCss);
                    }, 1500);
                },
            );
        },

        init: function () {
            this.initLists();
            this.initOptions();
            this.addToBasketAnimation();
            $('.js-subs-carousel', elm).trigger('refresh.owl.carousel');
        },
    };

    subs.init();
}
